import { PaymentUrl } from "../interface/payment"

export class InitPaymentSessionException extends Error { }

export const initPaymentSession = (amount: number, userId: string): Promise<PaymentUrl> => fetch(`/api/acquiring/replenishment/balance?amount=${amount}&user_id=${userId}`, {
    method: "GET",
    headers: {
        "Content-Type": "application/json"
    }
}).then((response) => {
    if (response.ok) {
        return response.json()
    }
    throw new InitPaymentSessionException()
}).catch(() => { throw new InitPaymentSessionException() })