import React, {useState, useRef, useEffect} from 'react';
import styled from "@emotion/styled";
import {InputProps} from "./Input";
import {css} from "@emotion/react";


const erroredInputState  = css`
    transition: .25s;
    border-color: rgb(206, 50, 50) !important;
`

const normalInputState = css `
    animation: none;
`

const CodeInput = styled.input<InputProps>`
    width: 2.69vw;
    height: 4.92vh;
    font-size: 1.56vw;
    background: #000;
    text-align: center;
    border-radius: 10px;
    border: 2px solid transparent;
    color: white;
    
    
    &:focus {
        transition: 0.5s all ease;
        outline: none;
        border: 2px solid rgb(65, 253, 19);
        caret-color: transparent;
    }

    ${(props) => props.error ?
            erroredInputState : normalInputState
    }
    
`

const CodeInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
`

interface ActivationCodeInputProps {
    codeLengthInit?: number;
    value?: string;
    onValueChange?: (value: string) => void;
    pattern?: RegExp;
}

const ActivationCodeInput: React.FC<ActivationCodeInputProps> = ({
    codeLengthInit,
                                                                     value = '',
                                                                     onValueChange,
                                                                     pattern = /^[0-9]*$/
                                                                 }) => {
    const [inputs, setInputs] = useState<string[]>([]);
    const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const codeLength = codeLengthInit ? codeLengthInit : 3;

    useEffect(() => {
        const initialInputs = value.split('').slice(0, codeLength);
        setInputs([...initialInputs, ...Array(codeLength - initialInputs.length).fill('')]);
    }, [value, codeLength]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, input_pattern: RegExp) => {

        let { value } = e.target;

        const newInputs = [...inputs];

        if (!pattern.test(value)) {
            value = "";
        }

        if (value.length > 1) {
            value = value.slice(-1);
        }

        if (value && input_pattern.test(value) && index < codeLength - 1 && !inputRefs.current[index + 1].value) {
            inputRefs.current[index + 1].focus();
        }

        newInputs[index] = value;
        setInputs(newInputs);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if ((e.key === 'Backspace') && !inputs[index] && index > 0) {
            inputRefs.current[index - 1].focus();
            const newInputs = [...inputs];
            newInputs[index - 1] = '';
            setInputs(newInputs);
        }

        if ((e.key === 'ArrowLeft' || e.key === 'ArrowUp') && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        if ((e.key === 'ArrowRight' || e.key === 'ArrowDown') && index < codeLength - 1) {
            inputRefs.current[index + 1].focus();
        }

        if (e.key === 'Delete') {
            const newInputs = [...inputs];

            if (inputRefs.current[index].value === '') {
                for (let i = index; inputs.length > i; i++) {
                    if (inputs[i] !== '') {
                        newInputs[i] = '';
                        setInputs(newInputs);
                        break;
                    }
                }
            } else {
                newInputs[index] = '';
                setInputs(newInputs);
            }
        }
        if(e.key === "Enter") {
            const newInputs = [...inputs];
            const value = newInputs.join('');
            if (onValueChange) {
                onValueChange(value);
            }
        }

        if (index === 0) {
            setTimeout(() => {
                inputRefs.current[index].setSelectionRange(1, 1);
            }, 0);
        }

    };

    const handleFocus = (index: number) => {
        setFocusedIndex(index);
        setTimeout(() => {
            inputRefs.current[index].setSelectionRange(1, 1);
        }, 0);
    };

    const handleBlur = () => {
        setFocusedIndex(null);
    };


    return (
        <CodeInputContainer>
            {inputs.map((char, index) => (
                    <CodeInput
                        key={index}
                        type="text"
                        value={char}
                        autoFocus={index === 0} 
                        onChange={(e) => handleChange(e, index, pattern)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onFocus={() => handleFocus(index)}
                        onBlur={handleBlur}
                        ref={(el) => (inputRefs.current[index] = el!)}
                        error={!pattern.test(inputRefs.current[index]?.value)}
                    />
            ))}
        </CodeInputContainer>
    );
};

export default ActivationCodeInput;
