import { FC, ReactNode } from "react"
import styled from "@emotion/styled"

export interface ErrorMessageProps {
    children?: ReactNode
}

const ErrorMessageContainer = styled.div`
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
`;

const ErrorMessageText = styled.p`
    position: absolute;
    color: rgb(206, 50, 50);
    text-align: center;
    background: black;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 10px;
    border: solid 1px rgb(206, 50, 50);
    margin: 0;
    transform: translateY(-50%);
`

export const ErrorMessage: FC<ErrorMessageProps> = (props) => {
    return <ErrorMessageContainer>
        <ErrorMessageText>
            {props.children}
        </ErrorMessageText>
    </ErrorMessageContainer>
}
