import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import "./index.css";
import { PayerInfo } from './routes/payerInfo';
import { SuccessPayed } from './routes/successPayed';
import {GlobalStyles} from "./globalStyle/globalStyle";
import {Global} from "@emotion/react";



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/success",
    element: <SuccessPayed />
  },
  {
    path: "/:userId",
    element: <PayerInfo />,
  },
]);

root.render(
  <React.StrictMode>
    <Global styles={GlobalStyles} />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
