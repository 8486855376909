import styled from "@emotion/styled"
import { CardContainer } from "../ui/cardContainer"
import logo from "../img/logo.png"
import access from "../img/Group 340.png"
import { Logo } from "../ui/logo"
import {Main} from "../layout";

const TitileText = styled.p`
    font-size: 36px;
  margin: 0;
  color: white;
  text-align: center;
  letter-spacing: 2px;
  font-family: "Gotham Pro", sans-serif;
  font-weight: bold;
`

const AccessImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    
    img{
        width: 40%;
    }
`

const ContentContainer = styled.div`
    display: flex;
    padding: 160px 0 0;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    gap: 50px;
`

export const SuccessPayed = () => {

    return<Main>
        <CardContainer>
            <Logo>
                <img src={logo} alt="logo" />
            </Logo>
            <ContentContainer>
                <TitileText>Ваша оплата прошла</TitileText>
                <AccessImgContainer>
                    <img src={access} alt="access" />
                </AccessImgContainer>

            </ContentContainer>

        </CardContainer>
    </Main>

}