import styled from "@emotion/styled";

export const Button = styled.button`
    background-color: rgb(65, 253, 19);
    border-radius: 40px;
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 20px;
    border-style: none;
    font-family: "Gotham Pro", sans-serif;

    &:hover{
        border: solid 1px black;
        transition: 1s;
        /* box-shadow: 10px 5px 5px rgb(65, 253, 19); */
    }
`
