import styled from '@emotion/styled';
import {css} from "@emotion/react";

export interface InputProps {
    error: boolean
}

const normalInputState = css`
    border-color: rgb(206, 50, 50);
    color: rgb(206, 50, 50);
    caret-color: rgb(206, 50, 50);

    &::placeholder {
        color: transparent;
    }
`

const erroredInputState = css `
    color: rgb(255, 255, 255);
    border-color: transparent;
    caret-color: rgb(65, 253, 19);
`

export const Input = styled.input<InputProps>`
    width: 100%;
    user-select: none;
    border-radius: 1rem;
    border-width: 2px;
    border-style: solid;
    background-color: rgb(0, 0, 0);
    text-align: start;
    padding-left: 0.5rem;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &:focus {
        transition: .25s all ease;
        outline: none;
        ${(props) => !props.error && css`
            border-color: rgb(65, 253, 19);
        `}
    }

    &::placeholder {
        color: rgba(255, 255, 255, 0.50);
        margin: 10px;
        font-size: 24px;
        font-weight: 300;
    }

    &:disabled {
        pointer-events: none;
        border-style: none;
    }

    &:not(:placeholder-shown) + label, &:focus + label {
        top: 0;
        left: 0.625rem;
        transform: translateY(-50%);
        border-radius: 0.25rem;
        background-color: rgb(0 0 0);
        line-height: 1vh;
        color: rgb(255 255 255);
    }

    //max-height: 86px;
    height: 72px;
    font-size: 24px;
    letter-spacing: 2px;
    
    ${(props) => props.error ? 
            normalInputState : erroredInputState
    }
`;

export const Label = styled.label`
    pointer-events: none;
    position: absolute;
    left: 0.875rem;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    background-color: transparent;
    padding: 0 0.25rem;
    color: rgb(255, 255, 255);
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &.error {
        color: rgb(206, 50, 50);
    }

    &.active {
        top: 0;
        background-color: rgb(0, 0, 0);
        font-size: 0.75rem;
        line-height: 1rem;
        border-radius: 0.25rem;
    }
`;