import styled from "@emotion/styled";
import BackgroundPhone from '../img/default-background.png'
import backgroundPC from '../img/backGroundPC.png'

export const Main = styled.div`
  padding: 1rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background-image: url(${BackgroundPhone}); /* Фон для мобильной версии по умолчанию */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  // Стили для ПК-версии
  @media (min-width: 600px) {
    background-image: url(${backgroundPC}); /* Фон для ПК версии */
  }
`;