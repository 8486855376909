import {User} from "../interface/user";

export class GetUserInfoException extends Error {
}

export const getUserInfo = (userId: string): Promise<User> => fetch(`/api/user/${userId}`, {
    method: "GET",
    headers: {
        "Content-Type": "application/json"
    }
}).then((response) => {
    if (response.ok) {
        return response.json()
    }
    throw new GetUserInfoException()
})
    .catch(() => {
        throw new GetUserInfoException()
    })