import styled from "@emotion/styled";

export const CardContainer = styled.div`
   padding: 100px 40px 0;
    width: 100%;
    max-width: 600px;
    background: rgba(0, 0, 0, 0.60);
    border-radius: 40px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); /* Для поддержки в Safari */
    border: 1px solid #41FD13;
    display: flex;
    flex-direction: column;

    /* Стили для ПК-версии */
    @media (min-width: 600px) {
        width: 600px;
        height: 800px;
        margin: 0 auto; /* Центрирует карточку по горизонтали */
    }

    /* Стили для мобильных устройств */
    @media (max-width: 600px) {
        width: calc(100% - 2rem);
        height: 800px;
    }
`